import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import EmailInputForm from 'components/shared/email-input-form';
import Heading from 'components/shared/heading';

const FORM_ID = 5;

const PopupForm = ({ title, buttonText }) => {
  const wrapperClassNames = classNames(
    /* base */ 'relative flex flex-col items-center justify-center bg-gray-1 p-4 w-full shadow-3xl -top-px',
    /* md-size */ 'md:flex-row',
    /* before */ 'before:absolute before:h-px before:w-full before:top-0 before:left-0 before:bg-black before:opacity-10'
  );

  return (
    <div className={wrapperClassNames}>
      <Heading
        className="text-center md:text-left text-lg lg:text-xl tracking-normal"
        tag="h2"
        size="xs"
        fontWeight="extra"
        asHTML
      >
        {title}
      </Heading>
      <EmailInputForm
        className="mt-2 ml-0 md:mt-0 md:ml-6 lg:ml-12 w-full max-w-[355px]"
        formName="eta-form"
        formId={FORM_ID}
        size="xxs"
        buttonText={buttonText}
        buttonTheme="primary"
      />
    </div>
  );
};

PopupForm.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default PopupForm;
