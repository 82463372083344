import { graphql } from 'gatsby';
import React from 'react';

import Iframe from 'components/pages/interactive-demo/iframe';
import PopupForm from 'components/pages/interactive-demo/popup-form';
import MainLayout from 'layouts/main';

const InteractiveDemo = ({
  data: {
    wpPage: { seo, acf },
  },
  pageContext,
}) => (
    <MainLayout seo={seo} pageContext={pageContext} withoutHeader withoutFooter>
      <Iframe src={acf.iframeSrc} />
      <PopupForm {...acf.interactiveDemoPopupForm} />
    </MainLayout>
  );

export default InteractiveDemo;

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      title
      uri
      acf {
        iframeSrc
        interactiveDemoPopupForm {
          title
          buttonText
        }
      }
      ...wpPageSeo
    }
  }
`;
