import PropTypes from 'prop-types';
import React from 'react';

const Iframe = ({ src }) => (
  <div className="relative h-[calc(100vh-114px)] md:h-[calc(100vh-82px)]">
    <iframe title="HeadsUp Interactive Demo" className="w-full h-full" src={src} />
  </div>
);

Iframe.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Iframe;
